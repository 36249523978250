<template>
  <div
    class="integralRecord"
    :style="!isWeixin_status ? 'padding-top: 50px' : ''"
  >
    <NavBar
      fixed
      v-if="!isWeixin_status"
      title="商城订单"
      @click-left="goBack"
      :left-arrow="isWeixin_status == false"
    >
    </NavBar>
    <List
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getPointOrder"
    >
      <div class="order-item" v-for="(item, idx) in listArr" :key="idx">
        <div class="order-item-top">
          <div class="order-item-topleft">
            <span>单号: {{ item.order_sn }}</span>
          </div>
          <span v-if="item.total_money_cash > 0 && item.buy_method > 1">
            支付方式: {{ item.buy_method | update_pay }}
          </span>
          <span v-else>支付方式:积分抵扣</span>
        </div>
        <div class="order-item-bottom">
          <div class="order-bot">
            <div class="order-main-left">
              <div class="main-item" v-if="item.goods">
                <span>商品名称:</span>
                <span>{{ item.goods.title }}</span>
              </div>
              <div class="main-item" v-else-if="item.good_type == 1">
                <span>优惠券:</span>
                <span>{{ item.num }}</span>
              </div>
              <div class="main-item" v-else-if="item.good_type == 2">
                <span>余额:</span>
                <span>{{ item.balance }}</span>
              </div>
              <div class="main-item">
                <span>数量:</span>
                <span>{{ item.num }}</span>
              </div>
              <div class="main-item">
                <span>下单时间:</span>
                <span>{{ item.create_time }}</span>
              </div>
            </div>
            <div
              class="order-main-right"
              v-if="item.buy_method > 1 && item.total_money_cash > 0"
            >
              <Button v-if="item.pay_status == 0" type="danger" size="mini" @click="checkOrderPayStatus(item.id)"
                >去支付</Button
              >

              <Button 
                v-if="item.pay_status == 1 && item.good_type == 0" 
                :type="item.status ?'primary':'info'" 
                size="mini"
                :disabled="item.status ? true:false"
                style="margin-bottom: 10px"
                @click="openQRcode(item.status, item.cancel_code)"
              >{{item.status ? '已核销':'去核销'}}
              </Button>

              <Button v-if="item.pay_status == 1" size="mini">已支付</Button>
            </div>
          </div>
        </div>
      </div>
    </List>
    <Overlay class="wrapper" :show="show" @click="show = false">
      <img class="block" v-if="qr_img" :src="qr_img" />
    </Overlay>
  </div>
</template>
<script>
import { NavBar, List, Button, Overlay } from "vant";
import UserInfo from "@/api/user";
import QRCode from 'qrcode';
import { wxPayTwo } from '@/utils/wxpay';
import CreditShop from "@/api/creditshop";
export default {
  components: {
    NavBar,
    List,
    Button,
    Overlay,
  },
  filters: {
    update_pay: function (val) {
      if (val == 1) return "积分抵扣";
      if (val == 2) return "微信+积分";
      if (val == 3) return "微信支付";
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      listArr: [],
      show: false,
      details: {},
      page: 0,
      qr_img: ''
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async openQRcode(status, url) {
      if (status) return
      await this.generateQR(url)
    },
    async goPay(id) { 
      try {
        const res = await CreditShop.payShoporder(id, this.isWeixin_status ? 'wx':'h5');
        if (res.code == 200) {
          await wxPayTwo(res.data);
        } else {
          this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error)
      }
    },
    async generateQR(url) {
      try {
        this.show = true
        this.qr_img = await QRCode.toDataURL(url)
      } catch (err) {
        this.$toast('生成二维码失败');
        console.error(err)
      }
    },
    // 检查订单是否可以支付
    async checkOrderPayStatus(id) {
      try {
        const res = await CreditShop.CheckShopOrder(id);
        if (res.code == 200) {
          await this.goPay(id);
        } else {
          this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getPointOrder() {
      try {
        const res = await UserInfo.getPointOrder(++this.page, 2);
        if (res.code == 200) {
          if (res.data.total_num > 0) {
            this.listArr = [...this.listArr, ...res.data.result];
            this.loading = false;
            if (res.data.result.length == 0) {
              this.finished = true;
            }
          } else {
            this.finished = true;
            this.loading = false;
          }
        } else {
          this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 240px;
  height: 240px;
  background-color: #fff;
}
.integralRecord {
  width: 100%;
  height: 100vh;
  background: rgba(244, 244, 244, 1);
  overflow-y: scroll;
  overflow-x: hidden;
  .order-item {
    width: 96%;
    height: auto;
    margin: 10px auto;
    border-radius: 6px;
    padding: 10px 0;
    background: #ffffff;
    > .order-item-top {
      width: 100%;
      height: 40px;
      display: flex;
      padding: 4px 10px;
      align-items: center;
      border-bottom: 1px solid #dfdfdf;
      justify-content: space-between;
      > .order-item-topleft {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
    > .open-card {
      width: 100%;
      padding: 10px 20px 0;
      line-height: 20px;
      text-align: right;
      font-size: 14px;
      display: flex;
      border-top: 1px solid #dfdfdf;
      justify-content: space-between;
      margin-top: 4px;
      align-items: center;
      & a:hover {
        text-decoration: none;
      }
      & span {
        display: block;
        width: 90px;
        height: 30px;
        text-align: center;
        color: #363636;
        line-height: 30px;
        border-radius: 4px;
        border-radius: 3px;
      }
      > .bill-ready {
        color: #ec0101 !important;
        background-color: #dfdfdf;
      }
      > .bill-end {
        color: #363636 !important;
        background-color: #dfdfdf;
      }
    }
    > .order-item-bottom {
      width: 100%;
      height: auto;
      padding: 0 10px;
      > .order-bot {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        > .order-main-left {
          width: 70%;
          display: flex;
          flex-direction: column;
          > .main-item {
            display: flex;
            font-size: 14px;
            height: 20px;
            align-items: center;
            justify-content: flex-start;
            & span:nth-child(2) {
              margin-left: 4px;
            }
          }
        }
        > .order-main-right {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 18px;
          margin-top: 10px;
          justify-content: center;
        }
      }
      > .order-time {
        font-size: 14px;
        font-weight: 560;
        color: #000000;
        line-height: 25px;
      }
    }
  }
}
</style>

